<template>
  <div
    class="wrapper"
    :class="`homeWrapper--${site}`"
  >
    <section
      v-if="ready && site"
      id="billboard"
      class="homeSection"
    >
      <div class="homeSection__inner">
        <header class="firstHeader">
          <img
            v-if="site != 2"
            class="logo"
            alt="BS BET"
            width="132"
            height="57"
            :src="logoSrc"
          >
          <div
            v-if="site == 2"
            class="h2WithBG"
          >
            <h2>
              {{ terms.billboard
              }}<!-- 客服排行榜 -->
            </h2>
          </div>
          <h2 v-else>
            {{ terms.billboard
            }}<!-- 客服排行榜 -->
          </h2>
          <div
            v-if="site == 1"
            class="langSwitcher"
          >
            <button
              type="button"
              class="langSwitcher__btn"
              @click="swithLang('cn')"
            >
              簡中
            </button>|
            <button
              type="button"
              class="langSwitcher__btn"
              @click="swithLang('en')"
            >
              EN
            </button>
          </div>
        </header>
        <b-tabs
          v-model="billboardTabIndex"
          class="billboard"
          content-class="billboard__tabContent"
          nav-wrapper-class="billboard__tabNav"
          fill
        >
          <b-tab :title="terms.thisWeek">
            <!-- 當周排行 -->
            <component
              :is="currBillboard"
              v-if="billboardTabIndex == 0"
              :mode="1"
            />
          </b-tab>
          <b-tab :title="terms.thisMonth">
            <!-- 當月排行 -->
            <component
              :is="currBillboard"
              v-if="billboardTabIndex == 1"
              :mode="2"
            />
          </b-tab>
          <b-tab :title="terms.lastMonth">
            <!-- 上月排行 -->
            <component
              :is="currBillboard"
              v-if="billboardTabIndex == 2"
              :mode="3"
            />
          </b-tab>
        </b-tabs>
      </div>
    </section>
    <section
      v-if="ready && site"
      id="allHistorySection"
      class="homeSection"
    >
      <div class="homeSection__inner">
        <header>
          <img
            v-if="site != 2"
            class="logo"
            alt="BS BET"
            width="132"
            height="57"
            :src="logoSrc"
          >
          <div
            v-if="site == 2"
            class="h2WithBG"
          >
            <h2>
              {{ terms.investigate
              }}<!-- 客服滿意度調查 -->
            </h2>
          </div>
          <h2 v-else>
            {{ terms.investigate
            }}<!-- 客服滿意度調查 -->
          </h2>
        </header>
        <div
          v-if="site != 3"
          class="allHistorySection__rule"
        >
          <h3>
            <span>{{ terms.voteDesc }}</span><!-- 投票規則說明 -->
          </h3>
          <ol>
            <li v-if="site != 5">
              {{ site == 1 ? terms.rule1_1 : terms.rule1 }}
            </li>
            <li>{{ terms.rule2 }}</li>
            <li>{{ terms.rule3 }}</li>
            <li>{{ terms.rule4 }}</li>
            <li>{{ terms.rule5 + (site == 5 ? terms.rulePS : "") }}</li>
          </ol>
          <p v-if="site != 5">
            <img
              class="allHistorySection__ruleHeart"
              :src="baseURL + 'img/heart.png'"
              alt=""
            >{{ terms.rulePS }}
          </p>
        </div>
        <template v-if="site">
          <TheLoadingRoller
            v-if="loadStatus == 1"
            class="allHistorySection__loading"
          />
          <div
            v-else
            class="allHistorySection__block"
          >
            <component
              :is="currAllMembers"
              :auth-state="authState"
            />
          </div>
        </template>
        <div
          v-if="site == 3"
          class="allHistorySection__rule"
        >
          <h3>
            {{ terms.voteDesc
            }}<!-- 投票規則說明 -->
          </h3>
          <ol>
            <li>{{ terms.rule2 }}</li>
            <li>{{ terms.rule3 }}</li>
            <li>{{ terms.rule4 }}</li>
            <li>{{ terms.rule5 + terms.rulePS }}</li>
          </ol>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Billboard from '@/components/Billboard.vue'
import Billboard2 from '@/components/Billboard2.vue'
import Billboard3 from '@/components/Billboard3.vue'
import Billboard5 from '@/components/Billboard5.vue'
import TheLoadingRoller from '@/components/TheLoadingRoller'
import AllMembers from '@/components/AllMembers'
import AllMembers2 from '@/components/AllMembers2'
import AllMembers3 from '@/components/AllMembers3'
import AllMembers5 from '@/components/AllMembers5'
import { EventBus } from '@/event-bus'
import { COOKIE_KIT } from '@/class/cookieKit'

export default {
  name: 'Home',
  components: {
    Billboard,
    Billboard2,
    Billboard3,
    Billboard5,
    TheLoadingRoller,
    AllMembers,
    AllMembers2,
    AllMembers3,
    AllMembers5
  },
  data () {
    return {
      ready: false,
      billboardTabIndex: 0,
      baseURL: '',
      site1Terms: null,
      authState: 'gsbet'
    }
  },
  computed: {
    cssIdx () {
      const idxs = [null, 1, 1, 2, 3, 4]
      return idxs[this.site]
    },
    logoSrc () {
      return `${this.baseURL}img/logo${this.cssIdx}.png`
    },
    currBillboard () {
      return this.site === 2
        ? 'Billboard2'
        : this.site === 3
          ? 'Billboard3'
          : this.site === 5
            ? 'Billboard5'
            : 'Billboard'
    },
    currAllMembers () {
      return this.site === 2
        ? 'AllMembers2'
        : this.site === 3
          ? 'AllMembers3'
          : this.site === 5
            ? 'AllMembers5'
            : 'AllMembers'
    },
    ...mapState({
      loadStatus: (state) => state.allMembers.loadStatus,
      uid: (state) => state.uid,
      site: (state) => state.site,
      terms: (state) => state.terms
    })
  },
  created: async function () {
    const LANGS = [null, 'en', 'tw', 'tw', 'tw', 'tw']
    try {
      const TERMS_RESPONSE = await this.$store.dispatch('getTexts')
      const TERMS = TERMS_RESPONSE.data
      const SITE_INDEX = 2
      this.$store.commit('SET_SITE_INDEX', SITE_INDEX)
      const terms = TERMS[LANGS[this.site]]
      const siteName = TERMS['siteName' + this.site]
      if (this.site === 1) {
        this.site1Terms = {}
        this.site1Terms.en = TERMS[LANGS[1]]
        this.site1Terms.cn = TERMS[LANGS[5]]
      }
      this.$store.commit('SET_TERMS', terms)
      const notices = TERMS.notices[LANGS[this.site]]
      this.$store.commit('SET_NOTICES', notices)
      this.$store.commit('SET_SITENAME', siteName)

      document.title = terms.clerkGrade + ' | ' + siteName
    } catch (err) {
      EventBus.$emit('show-notice', { idx: 1, err: err.toString() })
    }

    EventBus.$on('refresh-list', this.getData)

    if (this.$route.query.code) {
      if (
        !this.$route.query.state ||
        this.$route.query.state !== this.authState
      ) {
        EventBus.$emit('show-notice', {
          idx: 3,
          err: this.$route.query.error.toString()
        })
      } else {
        try {
          const response1 = await this.$store.dispatch(
            'getAToken',
            this.$route.query.code
          )

          const opt = {}
          if (response1.data.token && response1.data.expires_in) {
            opt['max-age'] = response1.data.expires_in
            COOKIE_KIT.setCookie('la_token', response1.data.token, opt)
            window.location.replace('./')
          } else {
            EventBus.$emit('show-notice', { idx: 1, err: '' })
          }
        } catch (err) {
          EventBus.$emit('show-notice', { idx: 1, err: err.toString() })
        }
      }
    } else if (this.$route.query.error) {
      EventBus.$emit('show-notice', {
        idx: 3,
        err: this.$route.query.error.toString()
      })
    } else {
      this.ready = true
      this.getData()
    }
  },
  mounted () {
    this.baseURL = process.env.BASE_URL
  },
  beforeDestroy: function () {
    EventBus.$off('refresh-list', this.getData)
  },
  methods: {
    getData: async function () {
      try {
        const response = await this.$store.dispatch('getMembersSum', 2)
        const snap = response.data
        this.$store.commit('SET_All_MEMBERS', snap)
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 4, err: err.toString() })
      }
    },
    swithLang (lang) {
      this.$store.commit('SET_TERMS', this.site1Terms[lang])
    }
  }
}
</script>

<style lang="scss">
@import "../scss/vars";

.wrapper {
  width: 100%;
  max-width: 500px;
  min-height: 100%;
  margin: auto;
  padding: 0;

  .homeSection {
    padding: $pt-page 20px 20px;
    background: {
      color: transparent;
      image: url(../assets/bg-wrapper1.jpg);
      position: center 0;
      repeat: no-repeat;
      size: cover;
    }
  }
  .billboard__tabContent {
    margin-top: 1rem;
  }

  @media (max-width: $maxW-xxs) {
    .homeSection {
      padding: $pt-page 12px 20px;
    }
  }
}
.firstHeader {
  position: relative;

  .langSwitcher {
    position: absolute;
    right: 0;
    top: 0;
  }

  .langSwitcher__btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: $color-white;
    font-size: 0.8rem;
  }
}

.logo {
  width: 200px;
  height: 57px;
  margin-bottom: 0;
}

h2 {
  font-size: 0.8rem;
  padding-top: 6px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.goldBorder {
  border: 1px solid $color-gold;
}

#billboard {
  .nav-tabs {
    border: none;

    .nav-item {
      margin-bottom: 0;
    }
    .nav-item + .nav-item {
      border-left: 1px solid $color-white;
    }

    .nav-link {
      color: $color-white;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;
      font-size: 0.6rem;

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: transparent;
      border: none;
      color: $color-yellow;
    }
  }
}

#allHistorySection {
  padding-bottom: 1.5rem;
}

.allHistorySection__rule {
  border: 1px solid $color-gold;
  background-color: $color-black;
  padding: 10px 20px;

  h3,
  ol,
  p {
    text-align: left;
    font-size: 0.6rem;
    margin: 0;
  }
  ol {
    padding-left: 20px;
  }
  .allHistorySection__ruleHeart {
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    margin-bottom: 0;
  }
}
.allHistorySection__loading {
  margin: auto;
  margin: 10% auto;
}
.allHistorySection__block {
  margin-top: 0.75rem;
  border: 1px solid $color-gold;
  background-color: $color-black;
  padding: 0 15px 0 20px;
}

.wrapper.homeWrapper--1 {
  .homeSection {
    background-image: url(../assets/bg-wrapper1.jpg);
  }
}
.wrapper.homeWrapper--2 {
  .homeSection {
    background-image: url(../assets/bg-wrapper5.jpg);
  }
  .h2WithBG {
    position: relative;
    width: 80%;
    height: 0;
    padding-bottom: 38%;
    margin: auto;
    background: {
      image: url(../assets/logo5.png);
      position: center center;
      repeat: no-repeat;
      size: contain;
    }

    h2 {
      position: absolute;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      top: 50%;
      text-align: center;
      font-size: 1.1rem;
      text-shadow: 1px -1px 1px $color-purple-site2,
        -1px -1px 1px $color-purple-site2, -1px 1px 1px $color-purple-site2,
        1px 1px 1px $color-purple-site2;
    }
  }

  #billboard {
    .nav-tabs {
      border: none;

      .nav-item + .nav-item {
        border-left: none;
      }

      .nav-link {
        color: $color-white;
        display: inline-block;
        padding: 0.5rem 0;
        font-size: 0.6rem;
        font-weight: bold;
        text-shadow: 0.5px -0.5px 0.5px $color-purple-site2,
          -0.5px -0.5px 0.5px $color-purple-site2,
          -0.5px 0.5px 0.5px $color-purple-site2,
          0.5px 0.5px 0.5px $color-purple-site2;

        /* background: {
          color: transparent;
          image: url(../assets/billboardListLink2.png);
          position: center center;
          repeat: no-repeat;
          size: auto 24px;
        } */

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        background-color: transparent;
        border: none;
        position: relative;

        &:after {
          display: inline-block;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          background-color: $color-purple-site2;
          width: 100%;
        }
      }
    }
  }
  .allHistorySection__rule {
    border: none;
    background-color: transparent;
    padding: 20px 10px;
    h3,
    ol,
    p {
      text-align: center;
      line-height: 1.75;
    }
    ol {
      list-style-position: inside;
    }
    h3 {
      font-size: 0.7rem;
      span {
        text-shadow: 0.5px -0.5px 0.5px $color-purple-site2,
          -0.5px -0.5px 0.5px $color-purple-site2,
          -0.5px 0.5px 0.5px $color-purple-site2,
          0.5px 0.5px 0.5px $color-purple-site2;
      }

      &:before,
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        background: {
          color: transparent;
          image: url(../assets/gbet-bling.png);
          position: center center;
          repeat: no-repeat;
          size: contain;
        }
      }
      &:after {
        transform: scaleX(-100%);
      }
    }
    .allHistorySection__ruleHeart {
      display: none;
    }
  }
  .allHistorySection__block {
    position: relative;
    border: 1px solid #6fa4f7;
    background: linear-gradient(to bottom, #c842db3d 10%, #00000005 60%);
    padding-bottom: 20px;

    &:after {
      content: "";
      position: absolute;
      left: -7px;
      bottom: -17px;
      width: 42px;
      height: 50px;
      background: {
        color: transparent;
        image: url(../assets/gbet-deco.png);
        position: center center;
        repeat: no-repeat;
        size: contain;
      }
    }
  }

  @media (max-width: $maxW-xxs) {
    .h2WithBG {
      h2 {
        top: 51%;
        font-size: 1rem;
      }
    }
    .allHistorySection__block {
      padding-bottom: 15px;
    }
  }
  @media (max-width: $maxW-xxxs) {
    .h2WithBG {
      h2 {
        top: 49%;
        font-size: 0.9rem;
      }
    }
    .allHistorySection__block {
      &:after {
        left: -5px;
        bottom: -13px;
        width: 32px;
        height: 40px;
      }
    }
  }
}
.wrapper.homeWrapper--3 {
  .homeSection {
    padding: 0 0 20px;
    background-image: url(../assets/bg-wrapper2.jpg);
  }
  .homeSection__inner {
    padding: $pt-page 20px 20px;
    background: {
      color: transparent;
      image: url(../assets/bg-billboard2.png);
      position: center 0;
      repeat: no-repeat;
      size: 100% auto;
    }
  }
  h2 {
    text-shadow: 1px -1px 1px #a2851a, -1px -1px 1px #a2851a,
      -1px 1px 1px #a2851a, 1px 1px 1px #a2851a;
  }
  .logo {
    width: 280px;
    height: auto;
  }

  #billboard {
    .nav-tabs {
      border: none;

      .nav-item + .nav-item {
        border-left: none;
      }

      .nav-link {
        color: $color-white;
        display: inline-block;
        padding: 0.5rem 0;
        font-size: 0.6rem;
        font-weight: bold;

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        background-color: transparent;
        border: none;
        color: $color-yellow3;
        position: relative;

        &:after {
          display: inline-block;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          background-color: $color-yellow3;
          width: 100%;
        }
      }
    }
  }

  .allHistorySection__rule {
    border: none;
    background-color: transparent;
    margin-top: $pt-page;

    h3 {
      color: $color-yellow3;
    }
  }

  .allHistorySection__block {
    border: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
}
.wrapper.homeWrapper--4 {
  .homeSection {
    background-image: url(../assets/bg-wrapper3.jpg);
  }

  .allHistorySection__rule {
    border: 1px solid $color-lightOrange;
  }

  .allHistorySection__block {
    border: 1px solid $color-lightOrange;
  }
}
.wrapper.homeWrapper--5 {
  .homeSection {
    background-image: url(../assets/bg-wrapper4.jpg);

    h2 {
      display: block;
      line-height: 2;
      text-indent: -9999px;
      margin-bottom: 30px;
      background: {
        color: transparent;
        image: url(../assets/service_investigate5.png);
        position: center 0;
        repeat: no-repeat;
        size: 80% auto;
      }
    }
    &:nth-child(2) {
      h2 {
        margin-bottom: 15px;
        background: {
          image: url(../assets/service_rank5.png);
          size: 65% auto;
        }
      }
    }
  }
  .logo {
    width: 70px;
    height: auto;
    margin-top: 30px;
  }

  .billboard__tabContent {
    margin-top: 1.5rem;
  }
  #billboard {
    .nav-tabs {
      .nav-item + .nav-item {
        border-left: 4px solid $color-blue-5;
      }

      .nav-link {
        font-size: 0.7rem;
        font-weight: bold;
        text-shadow: 2px 2px 8px lighten($color-blue-5, 10%),
          -2px -2px 8px lighten($color-blue-5, 10%),
          -2px 2px 8px lighten($color-blue-5, 10%),
          2px -2px 8px lighten($color-blue-5, 10%);

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        color: $color-yellow;
      }
    }
  }

  .allHistorySection__rule {
    border: none;
    background-color: transparent;
    padding: 20px 10px;

    h3,
    ol {
      padding-left: 0;
      text-align: center;
      list-style-position: inside;
    }
    h3 {
      font-size: 0.8rem;
      margin-bottom: 10px;
      font-weight: bold;
      text-shadow: 2px 2px 8px lighten($color-blue-5, 10%),
        -2px -2px 8px lighten($color-blue-5, 10%),
        -2px 2px 8px lighten($color-blue-5, 10%),
        2px -2px 8px lighten($color-blue-5, 10%);
    }
  }

  .allHistorySection__block {
    border: none;
    background-color: transparent;
  }

  @media (max-width: $maxW-xxs) {
    .homeSection {
      h2 {
        margin-bottom: 10px;
      }
    }
    #billboard {
      .nav-tabs {
        .nav-item + .nav-item {
          border-left: 2px solid $color-blue-5;
        }
        .nav-link {
          font-size: 0.6rem;
        }
      }
    }
  }
}
.wrapper.homeWrapper--1 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
